// Admin login
export const ADMIN_LOGIN_LOADING = "ADMIN_LOGIN_LOADING";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGIN_FAIL = "ADMIN_LOGIN_FAIL";

//Admin waitlist
export const GET_ADMINS_LIST_LOADING = "GET_ADMINS_LIST_LOADING";
export const GET_ADMINS_LIST_SUCCESS = "GET_ADMINS_LIST_SUCCESS";
export const GET_ADMINS_LIST_FAIL = "GET_ADMINS_LIST_FAIL";

export const ADD_ADMINS_WAITLIST_LOADING = "ADD_ADMINS_WAITLIST_LOADING";
export const ADD_ADMINS_WAITLIST_SUCCESS = "ADD_ADMINS_WAITLIST_SUCCESS";
export const ADD_ADMINS_WAITLIST_FAIL = "ADD_ADMINS_WAITLIST_FAIL";


