import * as types from "../../actionType";

const initialState = {
  addAdmins: []
}

export const addAdminWaitlistReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ADD_ADMINS_WAITLIST_LOADING:
            return {
              ...state,
              loading: true,
            };
          case types.ADD_ADMINS_WAITLIST_SUCCESS:
            return {
              ...state,
              addAdmins: action.payload,
              loading: false,
            };
          case types.ADD_ADMINS_WAITLIST_FAIL:
            return {
              ...state,
              error: action.payload,
              loading: false,
              addAdmins: [],
            };
            default:
                return state;
        }
    }