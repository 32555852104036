import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { adminLogin } from '../../Redux/Actions/Admin/Admin';
import './Login.css';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log('Form Data:', formData);

    // Dispatch login action
    dispatch(adminLogin(formData, navigate));

    // Simulate loading time
    setTimeout(() => {
      setLoading(false);
      // Reset form data
      setFormData({
        email: '',
        password: '',
      });
    }, 2000);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible); // Toggle password visibility
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="login-form">
        <h2 className="login-title">Login</h2>
        <div className="form-item">
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required // Ensure the field is filled
          />
        </div>
        <div className="form-item password-container">
          <input
            type={passwordVisible ? 'text' : 'password'} // Change input type based on visibility state
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            required // Ensure the field is filled
          />
          <span className="eye-icon" onClick={togglePasswordVisibility}>
            {passwordVisible ? '👁️' : '👁️‍🗨️'} {/* Eye icon with strikethrough */}
          </span>
        </div>
        <div className="form-item">
          <button type="submit" className="login-button" disabled={loading}>
            {loading ? 'Logging in...' : 'Log in'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;
