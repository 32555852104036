import React from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";
import AdminWaitlist from '../AdminWaitlist/AdminWaitlist';
import Admin from '../Admin/Admin';
import Login from '../Admin/Login/Login';
import Thankyou from '../AdminWaitlist/Thankyou';

const ComponentsRoutes = () => {
  let secure_token = Cookies.get("access_token");
  console.log("secure_token",secure_token);
  

  return (
    <div>
      <Routes>
        <Route path="/" element={<Navigate to="/waitlist" replace />} />
        <Route path="/waitlist" element={<AdminWaitlist />} />
        <Route path="/thankyou" element={<Thankyou />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={secure_token ? <Admin /> : <Navigate to="/login" />} />
      </Routes>
    </div>
  );
}

export default ComponentsRoutes;
