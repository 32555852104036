import * as types from "../../actionType";
import axios from "axios";

const addAdminsWaitlistLoading = () => ({
type: types.ADD_ADMINS_WAITLIST_LOADING,
});

const addAdminsWaitlistSuccess = (data) => ({
type: types.ADD_ADMINS_WAITLIST_SUCCESS,
payload: data,
});

const addAdminsWaitlistFail = (error) => ({
type: types.ADD_ADMINS_WAITLIST_FAIL,
payload: error,
});

export const addAdminsWaitlist = (data, navigate) => {    
return function (dispatch) {
    const Config = {
    method: "POST",
    url: `https://api.cohorts.in/admins/addAdmins`,
    data,
    };
    dispatch(addAdminsWaitlistLoading());
    axios(Config)
    .then((resp) => {
        dispatch(addAdminsWaitlistSuccess(resp.data));
        navigate("/thankyou")
    })
    .catch((error) => {
        console.log("errorforlsit", error);
        dispatch(addAdminsWaitlistFail(error));
        alert("This Email already exists!")
    });
};
};