import React from 'react'
import "./Thankyou.css"
import { ReactComponent as DontMissOne } from '../../Images/svg/dont-miss-one.svg';
import { ReactComponent as DontMissTwo } from '../../Images/svg/dont-miss-two.svg';
import { ReactComponent as DontMissThree } from '../../Images/svg/dont-miss-three.svg';
import { ReactComponent as CohortsLogo } from '../../Images/svg/cohorts-logo.svg';
import { ReactComponent as Linkedin } from '../../Images/svg/linkedin.svg';
import { ReactComponent as X } from '../../Images/svg/X.svg';
import WhyUsOne from "../../Images/why-us-one.png"
import Linked from "../../Images/linkedin.png"
import WhyUsTwo from "../../Images/why-us-two.png"
import WhyUsThree from "../../Images/why-us-three.png"
import WhyUsFour from "../../Images/why-us-four.png"
import ThankyouTwo from '../../Images/ThankyouTwo.png';
import Accordian from '../UIComponents/Accordian';
import Instagram from "../../Images/Instagram.png"
import { Button } from 'antd';
import { Link } from 'react-router-dom';

const Thankyou = () => {
  return (
    <div className='thank-you-main-container'>
        <div className='thank-you-logo-container'>
            <Link to="/waitlist"><CohortsLogo /></Link>
        </div>
        
        <div className='thank-you-first-container'>
            <p className='thank-you-onboard-message'>You’re Officially Onboard! <span className='no-style'>🎉</span></p>                
            <p className='thank-you-subtitle'>Be Part of the Revolution</p>
            <p className='thank-you-description'>Imagine a platform where followers become communities.
            </p>
            <img src={ThankyouTwo} alt='ThankyouTwo' width="100%" className='thank-you-glow-one' />
        </div>

        <div className='thank-you-second-container'>
            <p className='thank-you-why-choose-text'>The Future of Community is Here and Now</p>

            <div className='thank-you-why-choose-container'>
                <div className='thank-you-why-choose-box'>
                    <p className='thank-you-why-choose-title'>Communities, Not Just Followers</p>
                    <p className='thank-you-why-choose-description'>Transition from passive audiences to active communities. 
                        It's all about collaboration, growth, and genuine interaction, 
                        not just one-way communication.
                    </p>
                    <img src={WhyUsOne} alt='WhyUsOne' className='thank-you-why-choose-img' />
                </div>

                <div className='thank-you-why-choose-box'>
                    <p className='thank-you-why-choose-title'>Secure Course Hosting</p>
                    <p className='thank-you-why-choose-description'>Deliver and monetize valuable learning experiences through our secure, 
                        easy-to-access platform. Create courses that not only educate but also engage.
                    </p>
                    <img src={WhyUsTwo} alt='WhyUsTwo' className='thank-you-why-choose-img' />
                </div>
            </div>

            <div className='thank-you-why-choose-container'>
                <div className='thank-you-why-choose-box'>
                    <p className='thank-you-why-choose-title'>Live Webinars</p>
                    <p className='thank-you-why-choose-description'>Connect live with your community for workshops, 
                        Q&As, and more. Our platform supports seamless 
                        streaming to keep everyone engaged and informed.
                    </p>
                    <img src={WhyUsThree} alt='WhyUsThree' className='thank-you-why-choose-img' />
                </div>

                <div className='thank-you-why-choose-box'>
                    <p className='thank-you-why-choose-title'>Activity Leaderboards</p>
                    <p className='thank-you-why-choose-description'>Quickly identify and celebrate your most active 
                        members with our intuitive leaderboards, 
                        enhancing community interaction and participation.
                    </p>
                    <img src={WhyUsFour} alt='WhyUsFour' className='thank-you-why-choose-img' />
                </div>
            </div>



        </div>

        <div className='thank-you-third-container'>
            <p className='thank-you-early-access-text'>Early Access Benefits: Don’t Miss Out!</p>

            <div className='thank-you-dont-miss-box-container'>
                <div className='thank-you-dont-miss-box'>
                    <div>
                    <DontMissOne />
                    </div>
                    <p className='thank-you-dont-miss-box-title'>Priority to Pioneer</p>
                    <p className='thank-you-dont-miss-box-description'>Early members get the first pick on all new features, 
                        including exclusive tools for community engagement and growth.</p>
                </div>
                <div className='thank-you-dont-miss-box'>
                    <div>
                    <DontMissTwo />
                    </div>
                    <p className='thank-you-dont-miss-box-title'>Lock-In Low Rates</p>
                    <p className='thank-you-dont-miss-box-description'>Enjoy reduced pricing that will be part of our early adopter appreciation, 
                        ensuring you get the maximum with minimal investment.</p>
                </div>
                <div className='thank-you-dont-miss-box'>
                    <div>
                    <DontMissThree />
                    </div>
                    <p className='thank-you-dont-miss-box-title'>Exclusive Features</p>
                    <p className='thank-you-dont-miss-box-description'>Early access to tools like advanced analytics and custom engagement 
                        metrics that are crucial for growing your community effectively.</p>
                </div>
            </div>


        </div>

        <div className='thank-you-fourth-container'>
            <p className='thank-you-frequently-title'>Frequently Asked Questions</p>
            <div className='thank-you-frequently-accordian-container'>
                <Accordian questionClass={"thank-you-frequently-accordian-question"} answerClass={"thank-you-frequently-accordian-answer"} />
            </div>
        </div>

        <div className='thank-you-fifth-container'>
            <p className='thank-you-follow-title'>Follow our socials for all the new updates!</p>
            <div className="thank-you-follow-links">
                <button className='follow-us-btns' onClick={() => window.open("https://www.linkedin.com/company/cohorts-labs", "_blank", "noopener,noreferrer")}>
                    <img className='follow-us-images' src={Linked} alt='Linked' />
                </button>
                <button className='follow-us-btns' onClick={() => window.open("https://www.instagram.com/cohorts.in/", "_blank", "noopener,noreferrer")}>
                    <img className='follow-us-images' src={Instagram} alt="Instagram" />
                </button>
                <button className='follow-us-btns' onClick={() => window.open("https://x.com/CohortsLabs", "_blank", "noopener,noreferrer")}>
                    <X />
                </button>
            </div>

            <img src={ThankyouTwo} alt='ThankyouTwo' width="100%" className='thank-you-glow-two' />
        </div>

        
    </div>
  )
}

export default Thankyou