import React, { useState } from 'react'
import "./AdminWaitlist.css"
import { Button, Input } from 'antd'
import { ReactComponent as User } from '../../Images/svg/User.svg';
import { ReactComponent as Email } from '../../Images/svg/Email.svg';
import { ReactComponent as PhoneNumber } from '../../Images/svg/PhoneNumber.svg';
import { ReactComponent as CohortsLogo } from '../../Images/svg/cohorts-logo.svg';
import { ReactComponent as Instagram } from '../../Images/svg/instagram-btn.svg';
import { ReactComponent as UserWhite } from '../../Images/svg/userWhite.svg';
import { ArrowRightOutlined } from '@ant-design/icons';
import WaitlistMobileText from "../../Images/mobile-waitlist-text.png"
import { useDispatch, useSelector } from 'react-redux';
import { addAdminsWaitlist } from '../Redux/Actions/AdminWaitlist/AdminWaitlist';
import { Link, useNavigate } from 'react-router-dom';

const AdminWaitlist = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    adminEmail: '',
    adminPhoneNumber: '',
    userName: '',
    status: 'Waitlisted'
  });

  const addAdminResponse = useSelector((state) => state?.addAdmin?.addAdmins?.success)
  console.log("addAdminResponse",addAdminResponse);
  console.log("formData",formData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const textOnlyRegex = /^[A-Za-z\s]*$/; 
    const phoneRegex = /^[0-9]*$/;

    if (name === "firstName" || name === "lastName") {
      if (!textOnlyRegex.test(value)) {
        return;
      }
    }
  
    if (name === "adminPhoneNumber") {
      if (!phoneRegex.test(value)) {
        return;
      }
  
      if (value.length > 10) {
        return;
      }
    }
  
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (formData.firstName.trim() === "") {
      alert("Please enter the First Name.");
      return;
    }
    
    if (formData.lastName.trim() === "") {
      alert("Please enter the Last Name.");
      return;
    }

    if (!emailRegex.test(formData.adminEmail)) {
      alert("Please enter a valid email address.");
      return;
    }

    if (formData.adminPhoneNumber.length !== 10) {
      alert("Phone number must be exactly 10 digits.");
      return;
    }

    if (formData.userName.trim() === "") {
      alert("Please enter the User Name.");
      return;
    }

    dispatch(addAdminsWaitlist(formData, navigate))
  }

  return (
    <div className='waitlist-main-container'>
        <div className='waitlist-logo-buttons-container'>
            <Link to="/waitlist"><CohortsLogo /></Link>
            <div className='waitlist-contact-follow-button'>
              <Button className='waitlist-contact-us'  href="mailto:info@cohorts.in">Contact Us</Button>
              <Button className='waitlist-follow-us-btn' href="https://www.instagram.com/cohorts.in/"
                  target="_blank" icon={<div className='insta-btn-logo'><Instagram /></div>}>
                  Follow Us
              </Button>
            </div>
        </div>
        
        <div className='waitlist-listing-main-container'>
          <div className='waitlist-listing-container'>
            <p className='waitlist-heading'>The Power to Build Communities that Last – Exclusively for Visionary Leaders</p>
            <p className='waitlist-subheading'>The future of online communities is here. Be part of the exclusive cohort of leaders shaping the future of how India connects, learns, and thrives – powered by Cohorts</p>
          </div>
        

        <form className="waitlist-form" onSubmit={handleSubmit}>
          <div className='waitlist-form-container'>
            <div className='waitlist-form-desktop-container'>
              <div className='waitlist-form-single-container'>
                <p className='waitlist-form-label'>First Name</p>
                <Input 
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  maxLength={35}
                  required
                  size="large" placeholder="First Name" prefix={<User />} /> 
              </div>
              <div className='waitlist-form-single-container'>
                <p className='waitlist-form-label'>Last Name</p>
                <Input 
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  maxLength={35}
                  required
                  size="large" placeholder="Last Name" prefix={<User />} /> 
              </div>
            </div>
            <div className='waitlist-form-desktop-container'>
              <div className='waitlist-form-single-container'>
                <p className='waitlist-form-label'>Email</p>
                <Input 
                  type="email"
                  name="adminEmail"
                  value={formData.adminEmail}
                  onChange={handleChange}
                  className="form-input email-input"
                  maxLength={50}
                  required
                  size="large" placeholder="Email" prefix={<Email />} /> 
              </div>
              <div className='waitlist-form-single-container'>
                <p className='waitlist-form-label'>Phone Number</p>
                <Input 
                  type="tel"
                  name="adminPhoneNumber"
                  value={formData.adminPhoneNumber}
                  onChange={handleChange}
                  className="form-input phone-input"
                  maxLength={10}
                  required
                  size="large" placeholder="Phone Number" prefix={<PhoneNumber />} /> 
              </div>
            </div>
              <div className='waitlist-form-single-container'>
                <p className='waitlist-form-label'>Instagram User Name</p>
                <Input 
                  type="text"
                  name="userName"
                  value={formData.userName}
                  onChange={handleChange}
                  maxLength={35}
                  required
                  size="large" placeholder="User Name" prefix={<UserWhite />} /> 
              </div>
            <div className='waitlist-join-button-container'>
            <Button onClick={handleSubmit} className='waitlist-join-button' icon={<ArrowRightOutlined />}>
              Join Now
            </Button>
            </div>
          </div>
        </form>
        </div>
        <div className='waitlist-list-container'>
          <img className="waitlist-text" src={WaitlistMobileText} alt='WaitlistMobileText' />
        </div>
    </div>
  )
}

export default AdminWaitlist