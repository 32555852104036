import * as types from "../../actionType";

const initialState = {
  adminLoginData: [],
  getAdmins: []
}

export const AdminReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ADMIN_LOGIN_LOADING:
            return {
              ...state,
              loading: true,
            };
          case types.ADMIN_LOGIN_SUCCESS:
            return {
              ...state,
              adminLoginData: action.payload,
              loading: false,
            };
          case types.ADMIN_LOGIN_FAIL:
            return {
              ...state,
              error: action.payload,
              loading: false,
              adminLoginData: [],
            };
        case types.GET_ADMINS_LIST_LOADING:
            return {
              ...state,
              loading: true,
            };
          case types.GET_ADMINS_LIST_SUCCESS:
            return {
              ...state,
              getAdmins: action.payload,
              loading: false,
            };
          case types.GET_ADMINS_LIST_FAIL:
            return {
              ...state,
              error: action.payload,
              loading: false,
              getAdmins: [],
            };
            default:
                return state;
        }
    }