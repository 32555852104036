import * as types from "../../actionType";
import axios from "axios";
import Cookies from "js-cookie";

function decryptData(encryptedData) {
    const decrypted = JSON.parse(atob(encryptedData));
 return decrypted;
 }

const adminLoginLoading = () => ({
type: types.ADMIN_LOGIN_LOADING,
});

const adminLoginSuccess = (data) => ({
type: types.ADMIN_LOGIN_SUCCESS,
payload: data,
});

const adminLoginFail = (error) => ({
type: types.ADMIN_LOGIN_FAIL,
payload: error,
});
const getAdminsListLoading = () => ({
type: types.GET_ADMINS_LIST_LOADING,
});

const getAdminsListSuccess = (data) => ({
type: types.GET_ADMINS_LIST_SUCCESS,
payload: data,
});

const getAdminsListFail = (error) => ({
type: types.GET_ADMINS_LIST_FAIL,
payload: error,
});

export const adminLogin = (data, navigate) => {
    return function (dispatch) {
        const Config = {
        method: "POST",
        url: `https://api.cohorts.in/admin/login`,
        data
        };
        dispatch(adminLoginLoading());
        axios(Config)
        .then((resp) => {
            console.log(resp.data?.data?.token);
            dispatch(adminLoginSuccess(resp.data));
            Cookies.set("access_token", resp?.data?.data?.token);
            navigate("/admin");
        })
        .catch((error) => {
            console.log("errorforlsit", error);
            dispatch(adminLoginFail(error));
        });
    };
};

export const getAdminsList = () => {
    return function (dispatch) {
        const Config = {
        method: "GET",
        url: `https://api.cohorts.in/admins`,
        };
        dispatch(getAdminsListLoading());
        axios(Config)
        .then((resp) => {
            const decryptedData = decryptData(resp?.data?.data)
            dispatch(getAdminsListSuccess(decryptedData));
        })
        .catch((error) => {
            console.log("errorforlsit", error);
            dispatch(getAdminsListFail(error));
        });
    };
};