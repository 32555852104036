import React from 'react';
import { Collapse } from 'antd';
import "./Accordian.css"
const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const thankyouData = [
    {
        question: "What is Cohorts?",
        answer: "Cohorts is an all-in-one community-building platform that empowers creators, educators, and coaches to create, manage, and monetize their communities. Built for the Indian market, it offers seamless engagement and monetization tools."
    },
    {
        question: "How do I monetize my community on Cohorts?",
        answer: "You can monetize your community through flexible payment options, including one-time payments, subscriptions, event fees, and selling pre-recorded courses, with local payment methods like UPI and bank transfers integrated."
    },
    {
        question: "What are the benefits of joining the whitelist as an early creator?",
        answer: "Early creators receive exclusive benefits such as priority support, access to premium features, lifetime discounts, and the opportunity to test new features before the full launch."
    },
    {
        question: "What kind of content can I host on Cohorts?",
        answer: "You can host pre-recorded courses, live events, share exclusive content, and engage with your community through posts, polls, and discussions."
    },
    // {
    //     question: "How much does it cost to use Cohorts?",
    //     answer: "Our pricing is flexible: the basic community management is free, INR XXX/month for hosting pre-recorded courses, and INR XXXX/month for access to all features, including live events and leaderboards."
    // },
    // {
    //     question: "What can I do as a community member on Cohorts?",
    //     answer: "Cohorts is the only platform designed exclusively for Indian creators, educators, and communities, offering seamless local payment options and unique engagement tools."
    // },
    // {
    //     question: "Do I have to pay to join a community?",
    //     answer: " We offer flexible payment options – whether it's one-time payments, subscriptions, or event ticketing. Plus, we provide local payment solutions like UPI and bank transfers for seamless transactions."
    // },
    // {
    //     question: "How do I pay for courses or events on Cohorts?",
    //     answer: "As an early access admin, you'll get premium onboarding support with personalized guidance, tutorials, and one-on-one assistance from our team."
    // },
    // {
    //     question: "How do I pay for courses or events on Cohorts?",
    //     answer: "As an early access admin, you'll get premium onboarding support with personalized guidance, tutorials, and one-on-one assistance from our team."
    // },
    // {
    //     question: "How do I pay for courses or events on Cohorts?",
    //     answer: "As an early access admin, you'll get premium onboarding support with personalized guidance, tutorials, and one-on-one assistance from our team."
    // },
]



const Accordian = ({questionClass,answerClass}) => {
    const items = []
        {
            thankyouData?.map((data, index) => {
                return items.push ({
                        key: index + 1,
                        label: <p className={questionClass}>{data.question}</p>,
                        children: <p className={answerClass}>{data?.answer}</p>,
                })
            })
        }
  const onChange = (key) => {
    console.log(key);
  };
  return <Collapse items={items} defaultActiveKey={['1']} onChange={onChange} />;
};
export default Accordian;