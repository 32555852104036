import { combineReducers } from "redux";
import { AdminReducer } from "./Reducers/Admin/Admin";
import { addAdminWaitlistReducer } from "./Reducers/AdminWaitlist/AdminWaitlist";


const RootReducer = combineReducers ({
    Admin : AdminReducer,
    addAdmin: addAdminWaitlistReducer
  })

export default RootReducer;